
import { Component, Vue } from 'vue-property-decorator';
import {
  IPrompt,
  IPromptUpdate,
  IPromptCreate,
} from '@/interfaces';
import { dispatchGetPrompts, dispatchCreatePrompt } from '@/store/main/actions';

@Component
export default class CreatePrompt extends Vue {
  public valid = false;
  public name: string = '';
  public description: string = '';
  public template: string = '';
  public type: string = 'basic_prompt';
  public variables: string = '';
  public tags: string = '';

  public async mounted() {
    await dispatchGetPrompts(this.$store);
    this.reset();
  }

  public reset() {
    this.name = '';
    this.description = '';
    this.template = '';
    this.type = 'basic_prompt';
    this.variables = '';
    this.tags = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedPrompt: IPromptCreate = {
        name: this.name,
        template: this.template,
      };
      if (this.description) {
        updatedPrompt.description = this.description;
      }
      if (this.variables) {
        updatedPrompt.variables = this.variables;
      }
      if (this.tags) {
        updatedPrompt.tags = this.tags;
      }
      if (this.type) {
        updatedPrompt.type = this.type;
      }
      await dispatchCreatePrompt(this.$store, updatedPrompt);
      this.$router.push('/main/prompts');
    }
  }
}
